/*=========================================================================================
  File Name: moduleReservationActions.js
  Description: Reservation Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddReservation({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Reservation/AddReservation", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllReservation({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Reservation/GetAllReservations")
        .then((response) => {
          commit('SET_Reservation', response.data.Data)
          resolve(response)
       
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchReservation({ commit },search) {
    return new Promise((resolve, reject) => {
      axios.post("api/Reservation/SearchReservations",search)
        .then((response) => {
          commit('SET_Reservation', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateReservation({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Reservation/UpdateReservation?ID="+item.ID, item)
        .then((response) => {
          commit('UPDATE_Reservation', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  GetReservationByID(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Reservation/GetReservation?ID="+ itemid)
        .then((response) => {
          // commit('UPDATE_Reservation', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteReservation({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Reservation/DeleteReservation?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
