/*=========================================================================================
  File Name: moduleReservationMutations.js
  Description: Reservation Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Reservations.unshift(item)
  },
  SET_Reservation(state, Reservations) {
    state.Reservations = Reservations
  },
  UPDATE_Reservation(state, Reservation) {
    const ReservationIndex = state.Reservations.findIndex((p) => p.ID == Reservation.ID)
    Object.assign(state.Reservations[ReservationIndex], Reservation)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Reservations.findIndex((p) => p.ID == itemId)
    state.Reservations.splice(ItemIndex, 1)
},
}
