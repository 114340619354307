<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card v-bind:title="$t('Details')" class="mb-base">
      <div class="vx-col flex-1">
        <div class="vx-row ">
          <div
            class="  lg:w-2/3"
            style="padding-top:20px;padding-left:15px;padding-right:15px"
          >
            <vx-card class="flex-1" v-bind:title="$t('Reservation')">
              <div class="vx-col ">
                <div class="vx-row ">
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("Patient") }}
                    </label>
                    <vs-input
                      v-if="Reservation.Patient"
                      disabled
                      class="w-full"
                      v-model="Reservation.Patient.FullName"
                    />
                  </div>
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("doctors") }}
                    </label>
                    <vs-input
                      v-if="Reservation.DoctorSession"
                      disabled
                      class="w-full"
                      v-model="Reservation.DoctorSession.Doctor.FullName"
                    />
                  </div>
                </div>

                <div class="vx-row ">
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("DoctorComment") }}
                    </label>
                    <vs-input
                      disabled
                      class="w-full"
                      v-model="Reservation.DoctorComment"
                    />
                  </div>
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("Prescription") }}
                    </label>
                    <vs-input
                      disabled
                      class="w-full"
                      v-model="Reservation.Prescription"
                    />
                  </div>
                </div>

                <div class="vx-row ">
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75">{{ $t("PRICE") }} </label>
                    <vs-input
                      disabled
                      class="w-full"
                      v-model="Reservation.Price"
                    />
                  </div>
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("Currency") }}
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      disabled
                      v-model="Reservation.CurrencyID"
                      :options="currencies"
                      :reduce="ID => ID.ID"
                    />
                  </div>
                </div>

                <div class="vx-row ">
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("Discount") }}
                    </label>
                    <vs-input
                      disabled
                      class="w-full"
                      v-model="Reservation.Discount"
                    />
                  </div>
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("TOTALPRICE") }}
                    </label>
                    <vs-input
                      disabled
                      class="w-full"
                      v-model="Reservation.Total"
                    />
                  </div>
                </div>

                <div class="vx-row ">
                  <div class="mt-5 flex-1" style="padding-left:5px">
                    <label class="text-sm opacity-75"
                      >{{ $t("Status") }}
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      disabled
                      v-model="Reservation.StatusID"
                      :options="status"
                      :reduce="ID => ID.ID"
                    />
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
          <div
            class="vx-col lg:w-1/3"
            style="padding-top:20px;padding-left:15px;"
          >
            <div v-if="Reservation.DoctorSession">
              <vx-card class="flex-1" v-bind:title="$t('Session')">
                <h2>
                  <span style="color:red"> {{ $t("Date") }}</span> :
                  {{ Reservation.DoctorSession.DayUTC.split("T")[0] }}
                </h2>
                <h2>
                  <span style="color:red">{{ $t("TimeFrom") }}</span> :
                  {{ Reservation.DoctorSession.TimeFrom }}
                </h2>
                <h2>
                  <span style="color:red">{{ $t("TimeFrom") }} </span>:
                  {{ Reservation.DoctorSession.TimeTo }}
                </h2>
              </vx-card>
            </div>

            <div v-if="Reservation.ReservedPackage">
              <vx-card class="flex-1" v-bind:title="$t('ReservedPackage')">
                <h2>
                  <span style="color:red"> {{ $t("NumOfSession") }}</span> :
                  {{ Reservation.ReservedPackage.NumOfSession }}
                </h2>
                <h2>
                  <span style="color:red">{{ $t("Price") }}</span> :
                  {{ Reservation.Price }}
                </h2>
              </vx-card>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="danger"
              @click="cancle"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation//reservation/moduleReservation.js";
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";

export default {
  components: {
    // VuePerfectScrollbar,
    vSelect
  },
  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      selected: [],
      activePrompt: false,
      itemsPerPage: 10,
      isMounted: false,
      edit: false,
      ReservationAnswer: {},
      MaterialTypes: [],
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      Reservation: {},
      dataForDeleted: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      }
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    }
  },

  methods: {
    cancle() {
      this.$router.push("/Reservation");
    }
  },

  created() {
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule(
        "StatusList",
        moduleStatus
      );
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");
    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    // this.GetAllSpecialties();
    if (this.$route.params.ID) {
      this.$store
        .dispatch("ReservationList/GetReservationByID", this.$route.params.ID)
        .then(res => {
          this.Reservation = res.data.Data;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          //console.error(err);
        });
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
